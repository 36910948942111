import React from "react";
import styled from "@emotion/styled";
import Descriptor from "../Descriptor/index";
import StaticImage from "../StaticImage";
import CaseStudyContainer from "../case-study-general/CaseStudyContainer";
import TitleLine from "../case-study-general/CaseStudyTitleLine";
import Timeline from "../../content-blocks/Timeline/Timeline";
// import { FaLongArrowAltRight } from "react-icons/fa";

const timelineData = [
  {
    year: "2018",
    dotColor: "#73ddd3",
    dotPulseColor: "#56c4c3",
    events: [
      {
        title: "Research & Assessment",
        text:
          "Birchwood begins to work with Brandish on a comprehensive brand audit spanning the entire auto group and all of its subsidiaries.",
      },
      {
        title: "Brand strategy",
        text:
          "Our work on a new brand strategy begins and the focus starts to shift towards customer centricity. We also start developing the infrastructure in order to implement a true omni-channel retail and customer experience.",
      },
      {
        title: "Brand launch",
        text:
          "Birchwood’s “Experience is Everything” brand is launched into market with a city-wide, multi-channel brand launch campaign.",
      },
      {
        title: "Marketing strategy",
        text:
          "A new marketing strategy is developed that activates the Birchwood parent brand to drive awareness and traffic through to the dealership’s network.",
      },
    ],
  },
  {
    year: "2019",
    dotColor: "#666cfd",
    dotPulseColor: "#5344e0",
    events: [
      {
        title: "Building internal capabilities",
        text:
          "Our agency supports and develops Birchwood’s internal capabilities through improving the auto group’s competencies to support their own brand, digital marketing and their brand experience strategy.",
      },
      {
        title: "Organization-wide brand integration",
        text:
          "Through a series of internal employee focused sessions, the new brand is integrated throughout the entire organization’s 1000+ employees, aligning the entire team around the “Experience is Everything” brand platform.",
      },
      {
        title: "Record-breaking sale",
        text:
          "Birchwood’s largest retail sales event of the year is re-conceptualized and re-launched utilizing the foundational principles from the brand strategy. The 2019 Year End Sale reports record numbers and drives large YoY increases in awareness, engagement, lead volume and unit sales.",
      },
    ],
  },
  {
    year: "2020",
    dotColor: "#ffa3d3",
    dotPulseColor: "#ea90c4",
    events: [
      {
        title: "Global Pandemic",
        text:
          "Brandish works closely with Birchwood to develop a COVID-19 Response Strategy and framework, which is utilized to help the organization manage their communication and brand strategy through the pandemic.",
      },
      {
        title: "COVID-19 Response Strategy",
        text:
          "As a result of a strong brand strategy and marketing framework, Birchwood was able to successfully respond to the pandemic environment. The autogroup was mandated to shut down for one month during the initial phase of the pandemic. We worked with Birchwood to create a COVID-19 Response Strategy that shifted their customer experience and marketing digitally. We launched the Buy From Home campaign which allowed Birchwood to still connect and curate an experience for their customers remotely. This set up Birchwood for success when they were able to open their brick-and-mortar locations again. Once open, Birchwood hit record YoY retail sales and captured valuable market share in the months following the first wave.",
      },
      {
        title: "Campaign",
        text:
          "We create an omni-channel retail and customer experience sub-brand called “Birchwood You Drive”. This brand helps drive the organization’s digital retail strategy.",
      },
    ],
  },
];

const ResultsComponent = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  /* Container Styles */
  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }
  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }
  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 650px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 62.5%; */
      max-width: 750px;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 650px;
      }
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        /* margin-right: 12.586206896%; */
        margin-left: 23.189655172%;
        max-width: 750px;
      }
    }
  }
  .container-padding {
    padding: 4rem 1.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 7rem 12rem;
    }
  }

  .desktop-only {
    display: none;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: block;
    }
  }

  .mobile-only {
    display: block;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: none;
    }
  }

  .results-section {
    &--lightPeach {
      background-color: ${props => props.theme.colors.lightPeach};
    }
    &--darkestPurple {
      background-color: ${props => props.theme.colors.darkestPurple};
    }
  }

  .mb-5 {
    margin-bottom: 5rem;
  }

  /* Black title of page */
  .black-title-h2 {
    font-size: 4.8rem;
    line-height: 60px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 4rem;
    font-family: ${props => props.theme.fonts.medium};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-weight: 500;
      text-align: center;
      font-size: 6.5rem;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  /* Font Styling */
  .black-subtitle-h3 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 3.1rem;
    line-height: 38px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 2.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-bottom: 4.5rem;
    }
  }

  .white-subtitle-h3 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 3.1rem;
    line-height: 38px;
    color: ${props => props.theme.colors.offWhite};
    margin-bottom: 2.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-bottom: 4.5rem;
    }
  }

  .purple-subtitle-h4 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 2.5rem;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${props => props.theme.colors.darkerPurple};
    margin-bottom: 1.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 3.1rem;
      line-height: 38px;
    }
  }

  .gold-subtitle-h5 {
    font-family: ${props => props.theme.breakpoints.medium};
    font-size: 2.5rem;
    line-height: 32px;
    letter-spacing: 0.75px;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.darkGold};
  }

  .black-text-paragraph {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 2.5rem;
  }

  .gold-text-paragraph {
    font-size: 2.3rem;
    line-height: 35px;
    color: ${props => props.theme.colors.darkGold};
    margin-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
      margin-bottom: 4rem;
    }
  }

  .white-text-paragraph {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offWhite};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
    }
  }

  /* Image Captions */
  .image-section {
    margin-bottom: 4rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-bottom: 6rem;
    }
  }
  .image-caption {
    display: flex;
    align-items: center;

    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      margin-top: 4rem;
    }

    .research-pie-graphs {
      width: 18rem;
      height: 18rem;
    }

    p {
      text-align: left;
      /* width: 60%; */
      max-width: 170px;
      /* padding: 0 3rem; */
      margin: 0;
      margin-left: 3rem;
      font-family: ${props => props.theme.fonts.italic};
      font-size: 1.5rem;
      line-height: 25px;
      color: ${props => props.theme.colors.darkTeal};

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 2.5rem;
        line-height: 39px;
        margin-left: 4rem;
        letter-spacing: 0.75px;
        max-width: 429px;
      }
    }
  }
`;

// const MarketingResultImage = styled(StaticImage)`
//   width: 26.455026455%;
//   height: auto;
// `;

const DescriptorImage = styled(StaticImage)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const Image = styled(StaticImage)`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const CaseStudyResults = props => {
  return (
    // <ResultsComponent id={props.id || "results"}>
    //   <div className="execution-section container-padding">
    //     <CaseStudyContainer>
    //       <h2 className="execution-title">Results</h2>
    //       <TitleLine />
    //       <div className="sectionWrapper">
    //         <div className="leftWrapper">
    //           <Descriptor title="outcome" color="#D38B5C" alignment="center" />
    //           <DescriptorImage image={props.ResultsDescriptor} />
    //         </div>
    //         <div className="contentContainer">
    //           <p className="gold-text-content">Website</p>
    //           <div className="image-caption">
    //             <Image
    //               className="strategy-graph"
    //               image={props.BirchwoodDataVisualPie55}
    //             />
    //             <p className="strategy-quote">
    //               Since the launch of the new website, new users from all
    //               channels increased by +55%
    //             </p>
    //           </div>
    //           <div className="image-caption">
    //             <Image
    //               className="strategy-graph"
    //               image={props.BirchwoodDataVisualPie34}
    //             />
    //             <p className="strategy-quote">
    //               Direct traffic from within the province (Manitoba) increased
    //               by +34%
    //             </p>
    //           </div>
    //           <p className="gold-text-content">Social Media</p>
    //           <p className="black-body-text">
    //             Since the launch of the brand and the new website, social
    //             traffic increased from Manitoba users +190% year-over-year.
    //           </p>
    //           <Image
    //             image={props.BirchwoodResultsDataVisual07}
    //             className="mb-3"
    //           />
    //           <Image image={props.BwIGPost} />
    //           <p className="gold-image-caption">Birchwood social media</p>
    //           <p className="gold-text-content">Events</p>
    //           <p className="black-body-text">
    //             Once the new brand and website launched, traffic to Birchwood’s
    //             annual Year End Sale increased by +203%.
    //           </p>
    //           <Image
    //             image={props.BirchwoodResultsDataVisual07Mobile}
    //             className="mb-3"
    //           />
    //           <p className="gold-image-caption">Birchwood social media</p>
    //           {/* <p className="teal-body-text">
    //           Traffic in new markets increased on average by 220% year-over-year
    //           </p>
    //           <h2 className="execution-subtitle">Digital Marketing Results</h2>
    //         <p className="gold-text-content">Stats from 2019</p> */}
    //         </div>
    //       </div>
    //       {/* <Image
    //       css={css`
    //       width: 1265px;
    //       margin-bottom: 2.4rem;
    //       @media screen and (min-width: ${theme.breakpoints.md}) {
    //         margin: 5rem 0;
    //       }
    //       `}
    //       image={props.ResultsInstagram}
    //     /> */}
    //     </CaseStudyContainer>
    //   </div>
    // </ResultsComponent>
    <ResultsComponent id={props.id || "results"}>
      <div className="results-section results-section--lightPeach container-padding">
        <CaseStudyContainer>
          <h2 className="black-title-h2">Results</h2>
          <TitleLine />
          <div className="sectionWrapper">
            <div className="leftWrapper">
              <Descriptor title="outcome" color="#D38B5C" alignment="center" />
              <DescriptorImage image={props.ResultsDescriptor} />
            </div>
            <div className="contentContainer">
              <h3 className="black-subtitle-h3">What our work achieved</h3>
              <h4 className="purple-subtitle-h4">People-first focused</h4>
              <p className="black-text-paragraph">
                For the first time in the organization’s history, Birchwood
                embarked on a rebranding project which focused the entire
                organization around a brand platform focused on the customer
                experience.
              </p>
              <h4 className="purple-subtitle-h4">Brand unification</h4>
              <p className="black-text-paragraph">
                Historically, all brand, marketing and experience strategies
                were articulated through Birchwood’s dealerships and the parent
                brand functioned as an operating company. With a new brand
                strategy in play, the Birchwood parent brand is in a position to
                contribute to and lead the brand, marketing, and experience
                strategies, pushing value down to the dealership network.
              </p>
              <h4 className="purple-subtitle-h4">Informed decision-making</h4>
              <p className="black-text-paragraph">
                The automotive group is now focused on actively understanding
                the needs, wants, motivations, intent, and concerns of their
                target audience and using those insights to form brand,
                marketing, and experience strategies. We evaluate these
                strategies and refine based on reporting data and our up-to-date
                audience insights.
              </p>
              <h4 className="purple-subtitle-h4">Elevated performance</h4>
              <p className="black-text-paragraph">
                Transitioned the marketing environment from largely promotional
                and awareness driven to a strategic environment focused on
                addressing the needs of the car buyer and helping guide them
                through their buyer journey, resulting in more qualified
                prospects and elevated marketing performance across all
                channels.
              </p>
              <h4 className="purple-subtitle-h4">Internal sophistication</h4>
              <p className="black-text-paragraph">
                Focused on recalibrating and developing internal marketing and
                marketing support/production capabilities to support the brand,
                marketing and experience strategy.
              </p>
              <h4 className="purple-subtitle-h4">Unprecedented growth</h4>
              <p className="black-text-paragraph">
                In 2020, Birchwood saw significant increases in their YoY retail
                sales performance and market share.
              </p>
            </div>
          </div>
        </CaseStudyContainer>
      </div>
      <div className="results-section results-section--darkestPurple container-padding">
        <CaseStudyContainer>
          <div className="contentContainer--single">
            <h2 className="white-subtitle-h3">Birchwood Timeline</h2>
          </div>
          <div className="sectionWrapper">
            <div className="leftWrapper">
              <Descriptor
                title="our journey"
                color="#D38B5C"
                alignment="center"
              />
              <DescriptorImage image={props.ResultsClock} />
            </div>
            <div className="contentContainer">
              <p className="gold-text-paragraph">
                Our partnership with Birchwood started in 2018. We continue to
                work with the automotive group to this day. This timeline
                highlights our partnership’s key milestones.
              </p>
              <Timeline years={timelineData} />
            </div>
          </div>
        </CaseStudyContainer>
      </div>
      <div className="results-section results-section--lightPeach container-padding">
        <CaseStudyContainer>
          <div className="sectionWrapper">
            <div className="contentContainer--single">
              <h3 className="black-subtitle-h3">Performance</h3>
              <h5 className="gold-subtitle-h5">Website</h5>
              <div className="image-section">
                <div className="image-caption">
                  <Image
                    image={props.ResultsDataPie36}
                    className="research-pie-graphs"
                  />
                  <p>
                    Birchwood.ca (Birchwood brand) generated 36% more total
                    leads in YoY in 2020.
                  </p>
                </div>
              </div>
              <div className="image-section">
                <h5 className="gold-subtitle-h5">Marketing</h5>
                <div className="image-caption">
                  <Image
                    image={props.ResultsDataPie20}
                    className="research-pie-graphs"
                  />
                  <p>
                    Birchwood spent 20% less on marketing cross-group in 2020.
                  </p>
                </div>
              </div>
              <h5 className="gold-subtitle-h5">Campagin</h5>
              <div className="black-text-paragraph">
                During Birchwood’s annual Year End Sale, all channel traffic to
                Birchwood.ca increased +203% YoY.
              </div>
              <Image
                image={props.ResultsData08Desktop}
                className="desktop-only"
              />
              <Image
                image={props.ResultsData08Mobile}
                className="mobile-only"
              />
            </div>
          </div>
        </CaseStudyContainer>
      </div>
    </ResultsComponent>
  );
};

export default CaseStudyResults;
