import React, { useState } from "react";
import Layout from "../../components/layout";
import CaseStudyOverview from "../../components/case-study2/CaseStudy2Overview";
import CaseStudyInsights from "../../components/case-study2/CaseStudy2Insights";
import CaseStudyChallenge from "../../components/case-study2/CaseStudy2Challenge";
import CaseStudyStrategy from "../../components/case-study2/CaseStudy2Strategy";
import CaseStudyExecution from "../../components/case-study2/CaseStudy2Execution";
import CaseStudyResults from "../../components/case-study2/CaseStudy2Results";
import CaseStudyNavigation from "../../components/case-study-general/CaseStudyNavigation";
import { ContentProvider } from "../../../contexts/ContentContext";
import { useStaticQuery, graphql } from "gatsby";
import Hero from "../../components/Hero";

// Overview Assets
import OverviewDescriptor from "../../icons/case-studies/birchwood/BwOverviewDescriptor.svg";
import OverviewSectionIllustration from "../../icons/case-studies/birchwood/BwOverviewIllustration.svg";
import OverviewLeadsIllustration from "../../icons/case-studies/birchwood/BwOverviewLeadsIllustration.svg";

// Insight Assets
import InsightDescriptor from "../../icons/case-studies/birchwood/BwInsightDescriptor.svg";
import InsightIllustration01 from "../../icons/case-studies/birchwood/BwInsightIllustration01.svg";
import InsightIllustration02 from "../../icons/case-studies/birchwood/BwInsightIllustration02.svg";

import InsightGraph01 from "../../icons/case-studies/birchwood/InsightGraph01.svg";
import InsightIcon01 from "../../icons/case-studies/birchwood/InsightIcon01.svg";
import InsightIcon02 from "../../icons/case-studies/birchwood/InsightIcon02.svg";
import InsightIcon03 from "../../icons/case-studies/birchwood/InsightIcon03.svg";
import InsightIcon04 from "../../icons/case-studies/birchwood/InsightIcon04.svg";
import InsightIcon05 from "../../icons/case-studies/birchwood/InsightIcon05.svg";
import InsightIllustration03 from "../../icons/case-studies/birchwood/BwInsightIllustration03.svg";

// Challenge Assets
import ChallengeDescriptor from "../../icons/case-studies/birchwood/ChallengeDescriptor.svg";

// Strategy Assets
import StrategyDescriptor from "../../icons/case-studies/birchwood/StrategyDescriptor.svg";
import BirchwoodDataVisual from "../../icons/case-studies/birchwood/BirchwoodDataVisual.svg";
import BirchwoodDataVisualMobile from "../../icons/case-studies/birchwood/BirchwoodDataVisualMobile.svg";
import BirchwoodDataVisual04 from "../../icons/case-studies/birchwood/BirchwoodDataVisual04.svg";
import BirchwoodDataVisual04Mobile from "../../icons/case-studies/birchwood/BirchwoodDataVisual04Mobile.svg";
import BirchwoodStrategyIllustration from "../../icons/case-studies/birchwood/BirchwoodStrategyIllustration07.svg";
import BirchwoodNumber01 from "../../icons/case-studies/birchwood/BirchwoodNumber01.svg";
import BirchwoodNumber02 from "../../icons/case-studies/birchwood/BirchwoodNumber02.svg";
import BirchwoodNumber03 from "../../icons/case-studies/birchwood/BirchwoodNumber03.svg";
import BirchwoodNumber04 from "../../icons/case-studies/birchwood/BirchwoodNumber04.svg";
import BirchwoodIllustration08 from "../../icons/case-studies/birchwood/BirchwoodIllustration08.svg";
import StrategyGraph from "../../icons/case-studies/birchwood/StrategyGraph.svg";
// import BirchwoodLogo1 from "../../images/case-studies/birchwood/BirchwoodLogo1.png";
// import BirchwoodLogo2 from "../../images/case-studies/birchwood/BirchwoodLogo2.png";
// import BirchwoodLogo3 from "../../images/case-studies/birchwood/BirchwoodLogo3.png";
import BirchwoodDataVisualPyramid from "../../icons/case-studies/birchwood/BirchwoodDataVisualPyramid.svg";
import BirchwoodDataVisual05 from "../../icons/case-studies/birchwood/BirchwoodDataVisual05.svg";

// Execution Assets
import ExecutionDescriptor from "../../icons/case-studies/birchwood/ExecutionDescriptor.svg";
import BirchwoodExecutionIllustration09 from "../../icons/case-studies/birchwood/BirchwoodExecutionIllustration09.svg";
// ISSUE WITH THIS SVG - TRY AND FIX
import BirchwoodExecutionIllustration10 from "../../icons/case-studies/birchwood/BirchwoodExecutionIllustration10.svg";
import CustomerFirstTimer from "../../icons/case-studies/birchwood/CustomerFirstTimer.svg";
import CustomerLoyalist from "../../icons/case-studies/birchwood/CustomerLoyalist.svg";
// weird issue going on here
import CustomerSwitcher from "../../icons/case-studies/birchwood/CustomerSwitcher.svg";

// Results Assets
import ResultsDescriptor from "../../icons/case-studies/birchwood/ResultsDescriptor.svg";
import ResultsData08Desktop from "../../icons/case-studies/birchwood/Results_Data-Visual_08_Desktop.svg";
import ResultsData08Mobile from "../../icons/case-studies/birchwood/Results_Data-Visual_08_Mobile.svg";
import ResultsDataPie20 from "../../icons/case-studies/birchwood/Results_Data-Visual_Pie-20.svg";
import ResultsDataPie36 from "../../icons/case-studies/birchwood/Results_Data-Visual_Pie-36.svg";
import ResultsClock from "../../icons/case-studies/rc/Results_clock.svg";
// import BirchwoodDataVisualPie55 from "../../icons/case-studies/birchwood/BirchwoodDataVisualPie55.svg";
// import BirchwoodDataVisualPie34 from "../../icons/case-studies/birchwood/BirchwoodDataVisualPie34.svg";
// import BirchwoodResultsDataVisual07 from "../../icons/case-studies/birchwood/BirchwoodResultsDataVisual07.svg";
// import BirchwoodResultsDataVisual07Mobile from "../../icons/case-studies/birchwood/BirchwoodResultsDataVisual07Mobile.svg";

import { Helmet } from "react-helmet";

import "../../scss/swiper.scss";
import CaseStudyFooter from "../../components/case-study-general/CaseStudyFooter";

const Birchwood = () => {
  const [hidenav, setNav] = useState(false);

  function setVisibility(isVisible) {
    // console.log(isVisible);
    if (isVisible) {
      setNav(true);
    } else {
      setNav(false);
    }
  }

  const data = useStaticQuery(query);
  // console.log(data);
  const {
    wpCaseStudy: {
      id,
      databaseId,
      featuredImage,
      nodeType,
      HeroSection,
      FeaturedImages,
      CaseStudy,
      author,
      seo,
      slug,
      status,
      title,
      uri,
    },
    ...images
  } = data;

  // Items to pass to CaseStudyNavigation component
  const navItems = [
    {
      id: "overview",
      target: "#overview",
      title: "Overview",
    },
    {
      id: "insights",
      target: "#insights",
      title: "Insights",
    },
    {
      id: "challenge",
      target: "#challenge",
      title: "Challenge",
    },
    {
      id: "strategy",
      target: "#strategy",
      title: "Strategy",
    },
    {
      id: "execution",
      target: "#execution",
      title: "Execution",
    },
    {
      id: "results",
      target: "#results",
      title: "Results",
    },
  ];

  return (
    <ContentProvider
      value={{
        id,
        databaseId,
        featuredImage,
        nodeType,
        FeaturedImages,
        CaseStudy,
        HeroSection,
        author,
        seo,
        slug,
        status,
        title,
        uri,
      }}
    >
      <Layout>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
          title={seo.opengraphTitle || seo.title || `${title}`}
          meta={[
            {
              name: `robots`,
              content: `${seo.metaRobotsNoindex} ${seo.metaRobotsNofollow}`,
            },
            {
              name: `description`,
              content:
                seo.metaDesc ||
                `Our work carved out a new category within the Automotive Retail Industry with Birchwood Automotive Group as the leader. Read more to learn how we did it.`,
            },
            {
              name: `keywords`,
              content: seo.metaKeywords,
            },
            {
              property: `og:title`,
              content: seo.opengraphTitle || seo.title || `${title}`,
            },
            {
              property: `og:description`,
              content:
                seo.opengraphDescription ||
                seo.metaDesc ||
                `Our work carved out a new category within the Automotive Retail Industry with Birchwood Automotive Group as the leader. Read more to learn how we did it.`,
            },
            {
              property: `og:image`,
              content: seo.opengraphImage?.localFile?.publicURL,
            },
            {
              property: `og:type`,
              content: seo.opengraphType,
            },
            {
              property: `og:url`,
              content: seo.opengraphUrl,
            },
            {
              property: `og:site_name`,
              content: seo.opengraphSiteName,
            },
            {
              name: `twitter:card`,
              content: `summary`,
            },
            {
              name: `twitter:creator`,
              content: `Brandish Agency`,
            },
            {
              name: `twitter:title`,
              content:
                seo.twitterTitle ||
                seo.opengraphTitle ||
                seo.title ||
                `${title}`,
            },
            {
              name: `twitter:description`,
              content:
                seo.twitterDescription ||
                seo.opengraphDescription ||
                seo.metaDesc ||
                `Our work carved out a new category within the Automotive Retail Industry with Birchwood Automotive Group as the leader. Read more to learn how we did it.`,
            },
            {
              name: `twitter:image`,
              content:
                seo.twitterImage?.localFile?.publicURL ||
                seo.opengraphImage?.localFile?.publicURL,
            },
          ]}
        >
          <script type="application/ld+json">{seo?.schema?.raw}</script>
        </Helmet>
        <Hero
          data={{
            ...HeroSection,
            ...FeaturedImages,
            CaseStudy,
            featuredImage: featuredImage?.node,
            logoAlignment: "right",
          }}
        />
        <CaseStudyOverview
          id="overview"
          OverviewDescriptor={<OverviewDescriptor />}
          OverviewSectionIllustration={<OverviewSectionIllustration />}
          OverviewLeadsIllustration={<OverviewLeadsIllustration />}
        />
        <CaseStudyInsights
          id="insights"
          InsightDescriptor={<InsightDescriptor />}
          InsightIllustration01={<InsightIllustration01 />}
          InsightIllustration02={<InsightIllustration02 />}
          InsightGraph01={<InsightGraph01 />}
          InsightIcon01={<InsightIcon01 />}
          InsightIcon02={<InsightIcon02 />}
          InsightIcon03={<InsightIcon03 />}
          InsightIcon04={<InsightIcon04 />}
          InsightIcon05={<InsightIcon05 />}
          InsightIllustration03={<InsightIllustration03 />}
          {...images}
        />
        <CaseStudyChallenge
          id="challenge"
          ChallengeDescriptor={<ChallengeDescriptor />}
        />
        <CaseStudyStrategy
          id="strategy"
          StrategyDescriptor={<StrategyDescriptor />}
          BirchwoodDataVisual={<BirchwoodDataVisual />}
          BirchwoodDataVisualMobile={<BirchwoodDataVisualMobile />}
          BirchwoodDataVisual04={<BirchwoodDataVisual04 />}
          BirchwoodDataVisual04Mobile={<BirchwoodDataVisual04Mobile />}
          BirchwoodStrategyIllustration={<BirchwoodStrategyIllustration />}
          BirchwoodNumber01={<BirchwoodNumber01 />}
          BirchwoodNumber02={<BirchwoodNumber02 />}
          BirchwoodNumber03={<BirchwoodNumber03 />}
          BirchwoodNumber04={<BirchwoodNumber04 />}
          BirchwoodIllustration08={<BirchwoodIllustration08 />}
          BirchwoodStrategyGraph={<StrategyGraph />}
          BirchwoodDataVisualPyramid={<BirchwoodDataVisualPyramid />}
          {...images}
        />
        <CaseStudyExecution
          id="execution"
          ExecutionDescriptor={<ExecutionDescriptor />}
          BirchwoodDataVisual05={<BirchwoodDataVisual05 />}
          BirchwoodExecutionIllustration09={
            <BirchwoodExecutionIllustration09 />
          }
          BirchwoodExecutionIllustration10={
            <BirchwoodExecutionIllustration10 />
          }
          CustomerFirstTimer={<CustomerFirstTimer />}
          CustomerLoyalist={<CustomerLoyalist />}
          CustomerSwitcher={<CustomerSwitcher />}
        />
        <CaseStudyResults
          id="results"
          ResultsDescriptor={<ResultsDescriptor />}
          ResultsData08Desktop={<ResultsData08Desktop />}
          ResultsData08Mobile={<ResultsData08Mobile />}
          ResultsDataPie20={<ResultsDataPie20 />}
          ResultsDataPie36={<ResultsDataPie36 />}
          ResultsClock={<ResultsClock />}
          // BirchwoodDataVisualPie55={<BirchwoodDataVisualPie55 />}
          // BirchwoodDataVisualPie34={<BirchwoodDataVisualPie34 />}
          // BirchwoodResultsDataVisual07={<BirchwoodResultsDataVisual07 />}
          // BirchwoodResultsDataVisual07Mobile={
          //   <BirchwoodResultsDataVisual07Mobile />
          // }
          {...images}
        />
        <CaseStudyFooter
          setVisibility={setVisibility}
          caseStudy={CaseStudy.nextCaseStudy}
        />
        <CaseStudyNavigation navItems={navItems} hidenav={hidenav} />
      </Layout>
    </ContentProvider>
  );
};

const query = graphql`
  fragment File on File {
    name
    extension
    publicURL
    relativePath
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }

  fragment CaseStudyFragmentBirchwood on WpCase_study {
    databaseId
    title
    uri
    CaseStudy {
      fieldGroupName
      card {
        logo {
          ...ImageFragment
        }
        title
        description
        ctaText
        backgroundColor
        backgroundImageDesktop {
          ...ImageFragment
        }
        backgroundImageTablet {
          ...ImageFragment
        }
        backgroundImageMobile {
          ...ImageFragment
        }
      }
      hero {
        logo {
          ...ImageFragment
        }
      }
    }
    FeaturedImages {
      featuredImageMobile {
        ...ImageFragment
      }
      featuredImageTablet {
        ...ImageFragment
      }
      featuredImageDesktop {
        ...ImageFragment
      }
    }
    featuredImage {
      node {
        ...ImageFragment
      }
    }
  }

  {
    BwInsight: file(
      relativePath: {
        eq: "case-studies/birchwood/BwInsightInternalMessagingExample.png"
      }
    ) {
      ...File
    }
    BirchwoodLogo1: file(
      relativePath: { eq: "case-studies/birchwood/BirchwoodLogo1.png" }
    ) {
      ...File
    }
    BirchwoodLogo2: file(
      relativePath: { eq: "case-studies/birchwood/BirchwoodLogo2.png" }
    ) {
      ...File
    }
    BirchwoodLogo3: file(
      relativePath: { eq: "case-studies/birchwood/BirchwoodLogo3.png" }
    ) {
      ...File
    }
    CaseStudyColorSystem1: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyColorSystem1.png" }
    ) {
      ...File
    }
    CaseStudyColorSystem2: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyColorSystem2.jpg" }
    ) {
      ...File
    }
    CaseStudyColorSystem3: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyColorSystem3.jpg" }
    ) {
      ...File
    }
    CaseStudyPatterns1: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyPatterns1.jpg" }
    ) {
      ...File
    }
    CaseStudyPatterns2: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyPatterns2.png" }
    ) {
      ...File
    }
    CaseStudyPatterns3: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyPatterns3.png" }
    ) {
      ...File
    }
    CaseStudyExpression1: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyExpression1.jpg" }
    ) {
      ...File
    }
    CaseStudyExpression2: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyExpression2.jpg" }
    ) {
      ...File
    }
    CaseStudyExpression3: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyExpression3.jpg" }
    ) {
      ...File
    }
    CaseStudyExpression4: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyExpression4.jpg" }
    ) {
      ...File
    }
    CaseStudyExpression5: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyExpression5.jpg" }
    ) {
      ...File
    }
    CaseStudyExpression6: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyExpression6.jpg" }
    ) {
      ...File
    }
    CaseStudyBirchwoodToyotaPosters: file(
      relativePath: {
        eq: "case-studies/birchwood/Case-Study_Birchwood_Toyota_Posters_Desktop.png"
      }
    ) {
      ...File
    }
    CaseStudyBirchwoodToyotaFlag: file(
      relativePath: {
        eq: "case-studies/birchwood/Case-Study_Birchwood_Toyota_Flag_Desktop.png"
      }
    ) {
      ...File
    }
    CaseStudyBirchwoodToyotaLicense: file(
      relativePath: {
        eq: "case-studies/birchwood/Case-Study_Birchwood_Toyota_License-Plate-Cover_Desktop.png"
      }
    ) {
      ...File
    }
    CaseStudyBirchwoodGMCLanyard: file(
      relativePath: {
        eq: "case-studies/birchwood/Case-Study_Birchwood_GMC_Lanyard_Desktop.png"
      }
    ) {
      ...File
    }
    BwIGPost: file(
      relativePath: { eq: "case-studies/birchwood/BwIGPost.png" }
    ) {
      ...File
    }
    CaseStudyFooter: file(
      relativePath: { eq: "case-studies/birchwood/CaseStudyFooter.png" }
    ) {
      ...File
    }
    wpCaseStudy(databaseId: { eq: 4029 }) {
      id
      databaseId
      nodeType
      slug
      status
      title
      uri
      seo {
        canonical
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        opengraphImage {
          ...ImageFragment
        }
        title
        twitterDescription
        twitterImage {
          ...ImageFragment
        }
        twitterTitle
        schema {
          raw
        }
      }
      HeroSection {
        backgroundColor
        fieldGroupName
        hamburgerBackgroundColor
        heroType
        logoColor
        pageName
        subtitle
        title
      }
      PageCptFields {
        hideNavIcon
      }
      FeaturedImages {
        featuredImageMobile {
          ...ImageFragment
        }
        featuredImageTablet {
          ...ImageFragment
        }
        featuredImageDesktop {
          ...ImageFragment
        }
      }
      featuredImage {
        node {
          databaseId
          altText
          localFile {
            extension
            publicURL
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
      CaseStudy {
        fieldGroupName
        card {
          logo {
            ...ImageFragment
          }
          title
          description
          ctaText
          backgroundColor
          backgroundImageDesktop {
            ...ImageFragment
          }
          backgroundImageTablet {
            ...ImageFragment
          }
          backgroundImageMobile {
            ...ImageFragment
          }
        }
        hero {
          logo {
            ...ImageFragment
          }
        }
        nextCaseStudy {
          ...CaseStudyFragmentBirchwood
        }
      }
    }
  }
`;

export default Birchwood;
