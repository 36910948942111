import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../Descriptor";
import StaticImage from "../StaticImage";
import CaseStudyContainer from "../case-study-general/CaseStudyContainer";
import TitleLine from "../case-study-general/CaseStudyTitleLine";

const OverviewComponent = styled.div`
  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }

  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }

  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 650px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 62.5%; */
      max-width: 750px;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 650px;
      }
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        /* margin-right: 12.586206896%; */
        margin-left: 23.189655172%;
        max-width: 750px;
      }
    }
  }
  .gold-paragraph {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 2.3rem;
    line-height: 37px;
    color: ${props => props.theme.colors.darkGold};
    margin-top: 2.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
    }
  }
`;

const OverviewRedefineSection = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 3rem 1.5rem 5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  h1 {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 4.8rem;
    line-height: 60px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 3rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-weight: 500;
      width: 100%;
      font-size: 6.5rem;
      line-height: 80px;
      text-align: center;
      margin: 0 auto 2rem;
    }
  }

  .redefine-roles {
    font-family: ${props => props.theme.fonts.light};
    font-weight: 300;
    font-size: 1.8rem;
    line-height: 32px;
    color: ${props => props.theme.colors.offBlack};

    span {
      font-family: ${props => props.theme.fonts.medium};
      font-weight: 500;
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: inline-block;
      margin-right: 5rem;
      padding-top: 1.25rem;
    }
  }

  .gold-paragraph {
  }

  p {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};
    margin: 3rem 0;
  }
`;

const OverviewLeadsSection = styled.div`
  background-color: ${props => props.theme.colors.darkestPurple};
  padding: 4rem 1.5rem 7.5rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  h2 {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 4rem;
    line-height: 50px;
    color: ${props => props.theme.colors.lightPeach};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-left: 23.189655172%;
      width: 68%;
    }
  }

  .image-caption-box {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
      align-items: center;
    }
  }

  .teal-paragraph {
    font-family: ${props => props.theme.fonts.italic};
    font-size: 2.3rem;
    line-height: 35px;
    color: ${props => props.theme.colors.mediumTeal};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-left: 4rem;
      font-size: 2.5rem;
      line-height: 39px;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.lightPeach};
    margin: 3rem 0;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
      font-weight: 300;
    }
  }

  .marketing-leads-image {
    width: 60%;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 83%;
    }
  }
`;

const OverviewPotentialSection = styled.div`
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 4rem 1.5rem;

  .leftWrapper {
    margin-top: 17.5px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  .potential-title {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 4rem;
    line-height: 50px;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.medium};
      font-weight: 500;
      /* width: 56%; */
      margin-left: 23.189655172%;
      font-size: 4.8rem;
      line-height: 60px;
    }
  }

  .teal-paragraph {
    font-family: ${props => props.theme.fonts.italic};
    font-size: 2.3rem;
    line-height: 35px;
    color: ${props => props.theme.colors.mediumTeal};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
      letter-spacing: 0.75px;
      width: 71%;
    }
  }

  p {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};
    margin: 3rem 0;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.light};
      font-weight: 300;
    }
  }
`;
const DescriptorImage = styled(StaticImage)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const Image = styled(StaticImage)`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const CaseStudyOverview = props => {
  // console.log(props);
  return (
    <OverviewComponent id={props.id || "overview"}>
      <OverviewRedefineSection>
        <CaseStudyContainer>
          <h1>Building the contemporary automotive retail experience</h1>
          <TitleLine />
          <div className="sectionWrapper">
            <div className="leftWrapper">
              <Descriptor
                title="overview"
                color="#D38B5C"
                textAlign="center"
                alignment="center"
              />

              <DescriptorImage image={props.OverviewDescriptor} />
            </div>
            <div className="contentContainer">
              <h3 className="redefine-roles">
                <span>Partner:</span> Birchwood Automotive Group
              </h3>
              <h3 className="redefine-roles">
                <span>Our Role:</span> Brand strategy and marketing
              </h3>
              <p className="gold-paragraph">
                We first partnered with Birchwood in 2018 to reposition their
                business. Our work carved out a new category within the
                Automotive Retail Industry with Birchwood Automotive Group as
                the leader. We continue to work with Birchwood as a strategic
                partner.
              </p>
              <Image
                css={css`
                  margin: auto;
                `}
                image={props.OverviewSectionIllustration}
              />
              <p>
                Government regulations and Original Equipment Manufacturers
                (OEM) dictate a lot of what dealerships can and can’t do,
                causing large automotive retail groups like Birchwood to remain
                unchanged. Today, dealerships conduct business as they always
                have, while the world and consumer expectations leave them
                behind.
              </p>
            </div>
          </div>
        </CaseStudyContainer>
      </OverviewRedefineSection>
      <OverviewLeadsSection>
        <CaseStudyContainer>
          <h2>The Problem</h2>
          <div className="contentContainer--single">
            <p>
              Birchwood was looking to stay relevant in an industry that was
              being impacted by the digital world. Although Birchwood held
              dominant market share, new and different types of competitors were
              disrupting the automotive industry while OEM guidelines were
              increasingly restricting what dealerships could do. Birchwood was
              looking for a partner to help them stay relevant to their
              customers and competitive in their market.
            </p>
            <Image
              css={css`
                margin: auto;
              `}
              image={props.OverviewLeadsIllustration}
              className="marketing-leads-image"
            />
            <p>
              Some new competitors were smaller in scale and able to move
              quickly using digital-first strategies to build connections. And
              then there were new types of competitors, aggregator websites,
              like Autotrader. These platforms were delivering valuable
              information to customers before they even visited a dealership.
            </p>
            <p>
              Birchwood needed to pivot in order to stay relevant against both
              smaller more agile competitors and large online aggregators that
              were dominating the customer journey.
            </p>
          </div>
        </CaseStudyContainer>
      </OverviewLeadsSection>
      <OverviewPotentialSection>
        <CaseStudyContainer>
          <h3 className="potential-title">Birchwood Automotive Group</h3>
          <div className="contentContainer--single">
            <p className="gold-paragraph">
              Birchwood Automotive Group is the largest automotive dealership
              group in Manitoba, Canada. With 18 dealerships, Birchwood has over
              55 years of experience in the automotive industry.
            </p>
            <p>
              In order to start working toward a solution for Birchwood, we
              developed a deep understanding of the automotive retail industry
              and how global trends were impacting this specific market.
            </p>
          </div>
        </CaseStudyContainer>
      </OverviewPotentialSection>
    </OverviewComponent>
  );
};

export default CaseStudyOverview;
