import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../Descriptor/index";
import StaticImage from "../StaticImage";
import CaseStudyContainer from "../case-study-general/CaseStudyContainer";
import TitleLine from "../case-study-general/CaseStudyTitleLine";

const ExecutionComponent = styled.div`
  background-color: ${props => props.theme.colors.offWhite};
  padding: 4rem 1.5rem;
  font-family: ${props => props.theme.fonts.regular};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }
  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }
  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 650px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 62.5%; */
      max-width: 750px;
    }
    &--single {
      @media (min-width: ${props => props.theme.breakpoints.sm}) {
        max-width: 650px;
      }
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        /* margin-right: 12.586206896%; */
        max-width: 750px;
        margin-left: 23.189655172%;
      }
    }
  }

  /* Margin Bottom */
  .mb-3 {
    margin-bottom: 3rem;
  }
  .mb-7 {
    margin-bottom: 3.5rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-bottom: 7rem;
    }
  }

  .container-padding {
    padding: 4rem 1rem;
  }

  .execution-title {
    font-size: 4.8rem;
    line-height: 60px;
    margin-bottom: 4rem;
    color: ${props => props.theme.colors.offsetBlack};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 6.5rem;
      line-height: 80px;
      text-align: center;
      margin-bottom: 2rem;
    }
  }
  .execution-secondary-title {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 3.1rem;
    line-height: 38px;
    margin-bottom: 3rem;
    color: ${props => props.theme.colors.offsetBlack};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
    }
  }

  .gold-text-content {
    font-size: 2.3rem;
    line-height: 31px;
    margin-bottom: 1rem;
    color: ${props => props.theme.colors.darkGold};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 37px;
      margin-bottom: 1.5rem;
    }
  }

  .black-body-text {
    font-size: 1.8rem;
    line-height: 29px;
    margin-bottom: 2.5rem;
    color: ${props => props.theme.colors.offsetBlack};
  }

  .purple-title {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 2.5rem;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${props => props.theme.colors.darkerPurple};
    margin-bottom: 2rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 3.1rem;
      line-height: 38px;
      margin-bottom: 2.5rem;
    }
  }

  .gold-image-caption {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 1.5rem;
    line-height: 25px;
    color: ${props => props.theme.colors.darkGold};
  }

  .execution-subtitle {
    font-family: ${props => props.theme.fonts.medium};

    font-size: 2.5rem;
    line-height: 32px;
    margin-bottom: 2rem;
    color: ${props => props.theme.colors.offsetBlack};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-bottom: 3rem;
    }
  }

  .execution-caption {
    font-size: 1.5rem;
    line-height: 25px;
    text-align: center;
    margin: 2rem 0;
    color: ${props => props.theme.colors.darkGold};
  }

  .execution-website-image {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 1265px !important;
      margin: 0 auto;
    }
  }

  .image-textbox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;

    .teal-text {
      font-family: ${props => props.theme.fonts.italic};
      color: ${props => props.theme.colors.mediumTeal};
      font-size: 1.5rem;
      line-height: 25px;
      margin-top: 2.5rem;
      width: 30%;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        font-size: 2.7rem;
        line-height: 37px;
        letter-spacing: 0.81px;
        margin-top: 4rem;
      }
    }
  }

  .customer-types {
    margin-bottom: 7rem;
    .customer-bio {
      display: flex;
      flex-direction: column;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        flex-direction: row;
        justify-content: space-between;
      }
    }
    .bio-pic {
      width: 141.5px;
      height: 189.75px;
      margin-bottom: 1rem;
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        width: 226px;
        height: 303.06px;
      }
    }

    .customer-info {
      width: 100%;

      @media (min-width: ${props => props.theme.breakpoints.md}) {
        width: 59.58%;
      }

      .teal-text {
        font-family: ${props => props.theme.fonts.italic};
        color: ${props => props.theme.colors.mediumTeal};
        font-size: 1.5rem;
        line-height: 25px;
        margin-bottom: 2rem;

        @media (min-width: ${props => props.theme.breakpoints.md}) {
          font-size: 2.7rem;
          line-height: 37px;
          letter-spacing: 0.81px;
        }
      }
    }
  }
`;

const DescriptorImage = styled(StaticImage)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const Image = styled(StaticImage)`
  max-width: 100%;
  width: 100%;
  height: auto;
`;

const CaseStudyExecution = props => {
  return (
    <ExecutionComponent
      className="container-padding"
      id={props.id || "execution"}
    >
      <CaseStudyContainer>
        <h2 className="execution-title">Marketing Strategy & Execution</h2>
        <TitleLine />
        <div className="execution-content  sectionWrapper">
          <div className="leftWrapper">
            <Descriptor
              title="creating the <br> experience"
              color="#D38B5C"
              alignment="center"
            />
            <DescriptorImage image={props.ExecutionDescriptor} />
          </div>
          <div className="contentContainer">
            <p className="gold-text-content">
              Our marketing strategy would position Birchwood as market leader,
              while refining and growing their internal marketing capabilities.
            </p>
            <h3 className="execution-secondary-title">Marketing insights</h3>
            <p className="gold-text-content">A good reputation to build from</p>
            <p className="black-body-text">
              The Birchwood name is well-recognized and widely searched for. In
              conjunction with its good reputation, this brand equity can be
              leveraged to enhance marketing performance across the customer
              journey.
            </p>
            <p className="gold-text-content">
              Capitalize and promote Birchwood’s extensive inventory
            </p>

            <p className="black-body-text">
              The auto group has an extensive inventory of vehicles, both new
              and used, presenting a significant opportunity to further optimize
              search visibility.
            </p>
            <p className="gold-text-content">
              High Search Visibility for dealership searches
            </p>

            <p className="black-body-text">
              The auto group has strong search visibility, out-ranking their
              competitors across the majority of ‘dealership’ focused keywords
              and search phrases. Birchwood also has more branded searches than
              all of their competitors.
            </p>
            <Image
              image={props.BirchwoodDataVisual05}
              css={css`
                margin-bottom: 1rem;
              `}
            />
            <p className="gold-image-caption mb-3">
              *Based on an analysis of visibility across 100+ ‘dealership’
              focused keywords.
            </p>
            <h4 className="purple-title">
              The Birchwood website was underutilized
            </h4>

            <p className="black-body-text">
              The birchwood.ca website offered users a limited opportunity to
              explore and engage with content beyond inventory.
            </p>

            <p className="gold-text-content">Mobile Optimization</p>
            <p className="black-body-text">
              With over 70% of searches happening on mobile, it’s key to have a
              fast and responsive website. The majority of dealership websites
              are missing best practices and potentially losing a significant
              amount of visitors due to slow load times and unfriendly user
              experiences.
            </p>
            <p className="gold-text-content">
              Previous Birchwood Nissan website
            </p>
            <Image image={props.BirchwoodExecutionIllustration09} />
            <div className="image-textbox">
              <p className="teal-text text-1">Loading time on 3G: poor</p>
              <p className="teal-text text-2">
                Visitor loss (due to loading time)
              </p>
            </div>
            <p className="gold-text-content">Build for search visibility</p>
            <p className="black-body-text">
              Updating the Birchwood website would allow us to employ best
              practices to increase searchability.
            </p>
            <h4 className="purple-title">Understanding the customer journey</h4>
            <p className="black-body-text">
              Automotive customers are starting their buying journey before they
              visit a dealership. They research, cost compare, read reviews and
              narrow down their purchasing choices online without ever speaking
              to a salesperson. It was critical that we broke down the customer
              journey.
            </p>
            {/* Issue with this svg */}
            <Image
              image={props.BirchwoodExecutionIllustration10}
              className="mb-7"
            />
            <h3 className="execution-subtitle">
              Understanding different types of customers and their motivations
            </h3>
            {/* Here Now */}
            <div className="customer-types">
              <div className="customer-bio">
                <Image image={props.CustomerFirstTimer} className="bio-pic" />
                <div className="customer-info">
                  <h4 className="purple-title">1. The First Timer</h4>
                  <p className="teal-text">
                    I don’t know where to start and I need help…
                  </p>
                  <p className="black-body-text">
                    These cost-conscious first time car buyers are looking for a
                    fuel-efficient make and model. For the majority of this
                    group, their decision to purchase a vehicle is triggered by
                    some kind of life event – like moving, getting a new job or
                    getting a pet.
                  </p>
                </div>
              </div>
              <h4 className="purple-title">Needs:</h4>
              <p className="gold-text-content">Access to a lot information</p>
              <p className="black-body-text">
                So that they feel they are making informed decisions along their
                purchasing journey.
              </p>
              <p className="gold-text-content">An omni-channel experience</p>
              <p className="black-body-text">
                So that they have the opportunity to engage on the channel they
                feel most comfortable with.
              </p>
              <p className="gold-text-content">Social proof</p>
              <p className="black-body-text">
                So that they can relate to others who have gone through the same
                process before them.
              </p>
              <p className="gold-text-content">
                A helpful dealership experience
              </p>
              <p className="black-body-text">
                So that they don’t feel alienated or embarrassed when asking a
                lot of questions.
              </p>
            </div>

            <div className="customer-types">
              <div className="customer-bio">
                <Image image={props.CustomerLoyalist} className="bio-pic" />
                <div className="customer-info">
                  <h4 className="purple-title">2. The Loyalist</h4>
                  <p className="teal-text">
                    I’m considering a new vehicle and I know where I’m going
                    first
                  </p>
                  <p className="black-body-text">
                    These experienced vehicle buyers are older and typically
                    less likely to have kids. Even though they are brand loyal,
                    they still considered at least one other brand. This group
                    also uses fewer resources for their research with the
                    dealership website being the primary research channel.
                  </p>
                </div>
              </div>
              <h4 className="purple-title">Needs:</h4>
              <p className="gold-text-content">An ongoing relationship</p>
              <p className="black-body-text">
                So that they feel a sense of loyalty to the dealership they have
                chose to do business with.
              </p>
              <p className="gold-text-content">Exceptional Service</p>
              <p className="black-body-text">
                So that they feel their needs have been prioritized as a loyal
                customer.
              </p>
              <p className="gold-text-content">Quick Response Times</p>
              <p className="black-body-text">
                So that they don’t feel the need to look elsewhere because they
                haven’t been prioritized.
              </p>
              <p className="gold-text-content">
                A personalized dealership experience
              </p>
              <p className="black-body-text">
                So that they view the dealership they chose to do business with
                as a trusted partner.
              </p>
            </div>

            <div className="customer-types">
              <div className="customer-bio">
                <Image image={props.CustomerSwitcher} className="bio-pic" />
                <div className="customer-info">
                  <h4 className="purple-title">3. The Switcher</h4>
                  <p className="teal-text">
                    I know what I want and will find the best deal
                  </p>
                  <p className="black-body-text">
                    These experienced car buyers have the ‘comparison shopper’
                    mentality. They are typically looking for SUVs, trucks, or
                    luxury models and cross-shop up to 4 brands on average. This
                    group has a lower reliance on family and friends and view
                    the dealer as more of a facilitator as opposed to a trusted
                    partner.
                  </p>
                </div>
              </div>
              <h4 className="purple-title">Needs:</h4>
              <p className="gold-text-content">A seamless digital experience</p>
              <p className="black-body-text">
                So that they feel they can access all of the information they
                need while doing their research.
              </p>
              <p className="gold-text-content">
                Comparison and review focused content
              </p>
              <p className="black-body-text">
                So that they can review and compare different makes, models,
                options, and pricing.
              </p>
              <p className="gold-text-content">Quick response times</p>
              <p className="black-body-text">
                So that they feel confident when comparing the response times of
                other dealerships.
              </p>
              <p className="gold-text-content">
                An informative dealership experience
              </p>
              <p className="black-body-text">
                So that they feel they are being educated and informed—not sold.
              </p>
            </div>
            <h3 className="execution-subtitle">
              Different perspectives create new opportunities
            </h3>
            <p className="gold-text-content">
              By understanding each type of customer, we can cater our digital
              and content marketing to each specific customer group. This
              ensures we are targeting customers in different age groups and
              sets up Birchwood for continued growth by building a connection
              with a younger demographic.
            </p>
          </div>
        </div>
      </CaseStudyContainer>
    </ExecutionComponent>
  );
};

export default CaseStudyExecution;
