import React from "react";
import styled from "@emotion/styled";
import Descriptor from "../Descriptor/index";
import StaticImage from "../StaticImage";
import CaseStudyContainer from "../case-study-general/CaseStudyContainer";
import TitleLine from "../case-study-general/CaseStudyTitleLine";

const ChallengeComponent = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  background-color: ${props => props.theme.colors.lightPeach};
  padding: 4rem 1.5rem;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    padding: 7rem 12rem;
  }

  .container-padding {
    padding: 4rem 1.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      padding: 7rem 12rem;
    }
  }

  .sectionWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      display: flex;
    }
  }

  .leftWrapper {
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      width: 23.275862069%;
      padding: 0 6rem;
    }
  }

  .contentContainer {
    @media (min-width: ${props => props.theme.breakpoints.sm}) {
      max-width: 650px;
    }
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      /* width: 62.5%; */
      max-width: 750px;
    }
  }

  .challenge-title {
    font-size: 4.8rem;
    line-height: 60px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 4rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-family: ${props => props.theme.fonts.medium};
      font-weight: 500;
      font-size: 6.5rem;
      line-height: 80px;
      margin-bottom: 2rem;
      text-align: center;
    }
  }

  .challenge-subtitle {
    font-family: ${props => props.theme.fonts.medium};
    font-size: 3.5rem;
    line-height: 38px;
    color: ${props => props.theme.colors.offBlack};
    margin-bottom: 2rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
      line-height: 60px;
      margin-bottom: 3.5rem;
    }
  }
  .gold-text-content {
    font-size: 2.3rem;
    line-height: 37px;
    color: ${props => props.theme.colors.darkGold};
    margin-bottom: 1rem;
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      margin-bottom: 1.5rem;
    }
    &--mb7 {
      margin-bottom: 3rem;
      @media (min-width: ${props => props.theme.breakpoints.md}) {
        margin-bottom: 5rem;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
    }

    &--mb3 {
      margin-bottom: 3rem;
    }
  }

  .purple-text-content {
    font-size: 2.5rem;
    font-family: ${props => props.theme.fonts.medium};
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0.75px;
    color: ${props => props.theme.colors.darkerPurple};
    margin-bottom: 2.5rem;

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 3.1rem;
      line-height: 38px;
    }
  }

  .black-text-content {
    font-size: 1.8rem;
    line-height: 29px;
    color: ${props => props.theme.colors.offBlack};

    &--mb2 {
      margin-bottom: 2rem;
    }

    &--mb3 {
      margin-bottom: 3rem;
    }

    &--mb6 {
      margin-bottom: 6rem;
    }
  }

  .teal-caption {
    font-family: ${props => props.theme.fonts.italic};
    font-size: 2.3rem;
    line-height: 35px;
    letter-spacing: 0.69px;
    margin-bottom: 2rem;
    color: ${props => props.theme.colors.mediumTeal};
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
      letter-spacing: 0.75px;
    }
  }
  .citation {
    font-family: ${props => props.theme.fonts.regular};
    font-size: 1.5rem;
    line-height: 2.2rem;
    color: #d38b5c;
    margin-bottom: 3rem;
  }
`;
const DescriptorImage = styled(StaticImage)`
  width: 5.45rem;
  height: 5.45rem;
  margin: 3rem 0;
  @media screen and (min-width: ${props => props.theme.breakpoints.md}) {
    margin: 3rem auto;
  }
`;

const CaseStudyChallenge = props => {
  return (
    <ChallengeComponent id={props.id || `challenge`}>
      <CaseStudyContainer>
        <h2 className="challenge-title">The Challenge</h2>
        <TitleLine />
        <div className="sectionWrapper">
          <div className="leftWrapper">
            <Descriptor title="strategy" color="#D38B5C" alignment="center" />
            <DescriptorImage image={props.ChallengeDescriptor} />
          </div>
          <div className="contentContainer">
            <p className="gold-text-content gold-text-content--mb7">
              Birchwood Automotive Group needed to reposition their brand by
              shifting their focus externally on their customers. By doing so,
              Birchwood would ultimately differentiate themselves from their
              competition. Our challenge was to transform Birchwood into a
              customer-focused, purpose-driven brand.
            </p>
            <h3 className="challenge-subtitle">
              Our brand strategy needed to:
            </h3>
            <h3 className="purple-text-content purple-text-content--mb2">
              Create the Birchwood Experience
            </h3>
            <p className="black-text-content black-text-content--mb2">
              There was no differentiation between the Birchwood experience and
              the one their competitors were offering. By creating an experience
              that was unique to Birchwood, the automotive group would
              automatically set themselves apart.
            </p>
            <h4 className="gold-text-content">Customize the experience</h4>
            <p className="black-text-content black-text-content--mb2">
              Although OEM guidelines restrict Birchwood from owning and
              cultivating the entire experience, Birchwood still needed to
              customize their experience, while meeting their obligations to the
              OEM.
            </p>
            <h4 className="gold-text-content">Build the experience online</h4>
            <p className="black-text-content black-text-content--mb2">
              The Birchwood experience needed to be consistent across all
              communication touchpoints. This included digital.
            </p>
            <h4 className="gold-text-content">
              Understand the contemporary automotive customer
            </h4>
            <p className="black-text-content black-text-content--mb2">
              To build a great experience, we needed to understand the
              contemporary automotive customer’s needs and motivations.
              Birchwood had the opportunity to cater its brand experience
              directly to this audience.
            </p>
            <h3 className="purple-text-content purple-text-content--mb2">
              Build a retail brand
            </h3>
            <p className="black-text-content black-text-content--mb2">
              As consumers move away from visiting dealerships, brand will play
              a critical role when looking to engage consumers.
            </p>
            <p className="teal-caption teal-caption--mb2">
              Customer Experience will overtake price and product as the key
              brand differentiator by the year 2020.
            </p>
            <p className="citation">
              (Walker, 2013 "Customers 2020: A Progress Report")
            </p>
            <p className="black-text-content black-text-content--mb2">
              Customers now expect a similar brand experience across all
              channels. A well-defined experience across all communication
              touchpoints will differentiate Birchwood from competitor
              dealerships.
            </p>
            <h3 className="purple-text-content purple-text-content--mb2">
              Define Birchwood’s target audiences and map each audiences’
              car-buying journey:
            </h3>
            <p className="black-text-content">
              Birchwood is not actively participating in the ever-expanding
              pre-sales experience, which is now a fundamental part of the
              customer buying journey. Our goal was to learn more about the
              Contemporary Automotive Customer and how this audience group could
              be further segmented in order to further personalize our
              communications.
            </p>
          </div>
        </div>
      </CaseStudyContainer>
    </ChallengeComponent>
  );
};

export default CaseStudyChallenge;
